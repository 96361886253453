<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-body">
            <div class="text-right mb-3">
            <router-link class="btn btn-primary" to="/rekonsiliasi"> Daftar Rekonsiliasi</router-link>
            </div>
            <div class="row">
            <span class="callout callout-warning"><strong>Catatan:</strong> Mohon untuk menggunakan format excel dengan kolom judul pertama "AWB" dan kolom judul kedua "AMOUNT" dengan menggunakan huruf besar semua untuk memudahkan pengecekan.</span>
            </div>
            <form action="/target" ref="dzone" class="dropzone card" style="border-style: dashed;">
            <div class="overlay" v-if="loading">
            <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            </div>
            </div>
            <div class="dz-message text-muted" data-dz-message><h4><div><i class="fa fa-upload"></i></div><span>Upload file Anda disini</span></h4></div>
            </form>
            </div>
        </div>
        <form role="form" @submit.prevent="submitForm">
        <div class="card">
        <div class="card-header">
            <h3>PREVIEW</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 form-group">
                <label class="control-label">TANGGAL</label>
                <datepicker v-model="form.tanggal" id="tanggal" />
                </div>
            </div>
            <table class="table table-hover" ref="tblrekon">
            <thead>
                <tr>
                <th>AWB</th>
                <th>GRAND TOTAL</th>
                <th>STATUS</th>
                <th>STATUS ID</th>
                <th>ORDER ID</th>
                </tr>
            </thead>
            <tbody></tbody>
            </table>
        </div>
        <div class="card-footer">
            <button type="submit" id="submit" class="btn btn-primary" :disabled="disabled">
                Simpan
            </button>
        </div>
        </div>
        </form>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import Dropzone from "dropzone";
import XLSX from "xlsx";
import Swal from 'sweetalert2';
import $ from 'jquery';
import "dropzone/dist/dropzone.css";
import datepicker from "@/components/Datepicker";
import moment from "moment";

export default {
    name: 'FormRekonShipper',
    data() {
        return {
            errors: [],
            roles: '',
            loading: false,
            loadingDownload: false,
            data: [],
            formTitle: 'Tambah User',
            form: {
                tanggal: moment().format("DD/MM/YYYY"),
            },
            disabled: true,
        }
    },
    components: {
        datepicker,
    },
    computed: {},
    created: function() {

    },
    methods: {
        btnFile() {
            var input = document.querySelector("#excelFile");
            input.click();
        },
        excelImport(file) {
        const e = this.$refs;
        var input = file;
        this.tbl.api().clear().draw();
        var reader = new FileReader();
        reader.onload = () => {
            var fileData = reader.result;
            var wb = XLSX.read(fileData, { type: "binary" });
            var worksheet = wb.Sheets[wb.SheetNames[0]];
            var rowObj = XLSX.utils.sheet_to_json(worksheet);
            let data = rowObj;
            console.log(data);

            if (
            !data[0].AMOUNT ||
            !data[0].AWB
            ) {
            Swal.fire("Proses gagal", `data atau kolom tidak sesuai!`, "error");
            this.loading = false;
            } else {
                var allData = { group: data };
                var urlSubmit = "/order/rekonsiliasi/upload";

                authFetch(urlSubmit, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(allData),
                }).then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                this.loading = false;
                var data = js.data;
                for (var i = 0; i < data.length; i++) {
                    let nRow = this.tbl.fnAddRow(data);
                    var aData = {
                        no_resi: data[i].no_resi,
                        grand_total: data[i].grand_total,
                        status_id: data[i].status_id,
                        order_id: data[i].order_id,
                        status: data[i].status,
                    };
                    this.tbl.fnUpdate(aData, nRow);
                    $("td .btn.edit", nRow).click();
                }
                });
            }
        };
        reader.readAsBinaryString(input);
        },
        downloadTemplate: function()
        {
            this.loadingDownload = true;
            authFetch("/file/aset/template", {
                method: 'GET',
            })
            .then(response => response.blob())
            .then(blob => {
                this.loadingDownload = false;
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "template1.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });

        },
        submitForm: function (ev) {
        var self = this;
        const e = this.$refs;

        moment(self.form.tanggal).format("YYYY-MM-DD");
        
        var data = this.tbl.getSerialize();
        var items = [];
        this.tbl
        .api()
        .rows()
        .every(function(rowIdx, tableLoop, rowLoop) {
        var d = this.data();
        items.push(d);
        });

        var allData = { group: self.form, items: items };
        var urlSubmit = "/order/rekonsiliasi";

        Swal.fire({
        title: "Simpan?",
        showCancelButton: true,
        confirmButtonText: `Ya`,
        cancelButtonText: "Tidak",
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
        authFetch(urlSubmit, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(allData),
        })
        .then((res) => {
            return res.json();
        })
        .then((js) => {
            if (js.success) {
                Swal.fire("Proses Berhasil", ``, "success");
                this.form = {};
                this.tbl.api().ajax.reload();
            } else {
                Swal.fire("Proses gagal", ``, "error");
            }
        });
        }
        });

        ev.preventDefault();
        },
    },
    mounted() {
        /*var input = event.target;
        var reader = new FileReader();
        reader.onload = () => {
            var fileData = reader.result;
            var wb = XLSX.read(fileData, { type: "binary" });
            var worksheet = wb.Sheets[wb.SheetNames[0]];
            var rowObj = XLSX.utils.sheet_to_json(worksheet);
            let data = rowObj;
            console.log(data);

            if (
            !data[0].AMOUNT ||
            !data[0].AWB
            ) {
            Swal.fire("Proses gagal", `data atau kolom tidak sesuai!`, "error");
            } else {
                var allData = { group: data };
                var urlSubmit = "/order/rekonsiliasi/upload";

                authFetch(urlSubmit, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(allData),
                }).then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                var data = js.data;
                for (var i = 0; i < data.length; i++) {
                    let nRow = this.tbl.fnAddRow(data);
                    var aData = {
                        no_resi: data[i].no_resi,
                        grand_total: data[i].grand_total,
                        status_id: data[i].status_id,
                        order_id: data[i].order_id,
                        status: data[i].status,
                    };
                    this.tbl.fnUpdate(aData, nRow);
                    $("td .btn.edit", nRow).click();
                }
                });
            }
        };
        reader.readAsBinaryString(input.files[0]); */
        const e = this.$refs;
        const self = this;

        //upload excel
        Dropzone.autoDiscover = false;
        let myDropzone = new Dropzone(this.$refs.dzone, {
            uploadMultiple: false, maxFiles: 1, maxFilesize: 20,
            acceptedFiles: ".xls,.xlsx", createImageThumbnails: false,
            autoProcessQueue: false,
            url: 'getMeSomeUrl',
            accept: function(file, done) {
                console.log('accept', file, done)
                this.removeFile(file);
            },
            success: function(file)
            {
                this.removeFile(file);
            }
        });
        myDropzone.on("addedfile", file => {
        console.log(`File added: ${file.name}`);
        console.log(file)
        this.loading = true;
        var input = file;
        this.excelImport(input);
        });
        myDropzone.on("maxfilesexceeded", function (file) {
            myDropzone.removeAllFiles();
        });

        this.tbl = createTable(e.tblrekon, {
        title: "",
        serverSide: false,
        ajax: "/order/rekonsiliasi",
        // paramData: function (d) {},
        selectedRowClass: "",
        frame: false,
        displayLength: 100,
        lengthChange: false,
        filter: false,
        columns: [
            { data: "no_resi" },
            { data: "grand_total" },
            { data: "status" },
            { data: "status_id", visible: false },
            { data: "order_id", visible: false },
        ],
        rowCallback: function (row, data) {
            if (data.status_id > 0) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        }
        });
    }
}
</script>
<style scoped>
.drag-area {
  border: 2px dashed rgb(114, 114, 114);
  min-height: 250px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid rgb(133, 133, 133);
}

.drag-area .icon {
  font-size: 100px;
  color: rgb(139, 139, 139);
}

.drag-area header {
  font-size: 30px;
  font-weight: 500;
  color: rgb(126, 126, 126);
}

.drag-area span {
  font-size: 25px;
  font-weight: 500;
  color: rgb(129, 129, 129);
  margin: 10px 0 10px 0;
}

.drag-area button {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: rgb(206, 206, 206);
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
</style>